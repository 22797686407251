import { Document, DocumentJson } from "../api/documentClient";
import { Person, PersonJson } from "../people/types";
import { Program, ProgramJson } from "../programs/types";

export interface SessionJson {
  id: number;
  slug: string;
  program: ProgramJson | number;
  description?: string | null;
  name: string;
  director?: PersonJson | number;
  date_confirmed?: boolean;
  primary_location?: string;
  primary_overnight_location?: string;
  age_range?: string;
  max_participants?: number;
  registered_participants?: number;
  waitlist?: YesNo;
  approval_status?: string;
  run_status: string;
  canceled?: boolean;
  canceled_on?: string;
  type_code?: string;
  type_info?: string;
  type_display?: string;
  registered_student_org_type?: string;
  approval_source?: string;
  approval_type?: string;
  approval_notes?: string;
  custodial_care?: string;
  custodial_care_display?: string;
  youth_supervision_plan?: DocumentJson | number;
  health_and_safety_plan?: DocumentJson | number;
  emergency_plan?: DocumentJson | number;
  escalation_matrix?: DocumentJson | number;
  orientation_agenda?: DocumentJson | number;
  third_party_agreement?: DocumentJson | number;
  staff_roster?: DocumentJson | number;
  start_date?: string;
  end_date?: string;
  exclude_from_integrations?: boolean;
  participant_count?: number;
  staff_count?: number;
  staff_background_checks?: boolean;
  staff_required_training?: boolean;
  staff_orientation?: boolean;
  staff_youngest_age?: number;
  is_compliant?: boolean;
  created_at?: string;
  updated_at?: string;
  forms_due?: string;
  forms_close?: string;
  registration_open?: string;
  registration_close?: string;
  fee?: number;
  deposit?: number;
  campus_admin_fee: number;
  has_youth_supervision_plan?: boolean;
  has_health_and_safety_plan?: boolean;
  has_emergency_plan?: boolean;
  has_escalation_matrix?: boolean;
  has_orientation_agenda?: boolean;
  has_third_party_agreement?: boolean;
  has_staff_roster?: boolean;
  has_volunteers?: string;
  volunteer_supervisor?: string;
  volunteer_supervisor_email?: string;
  grant_funded?: string;
  upward_bound_trio?: string;
  dpi_scholarship?: string;
  athletic_activity?: string;
  music_activity?: string;
  water_activity?: string;
  in_state_travel?: string;
  out_state_travel?: string;
  for_credit?: string;
  league?: string;
  notes?: string;
  gl_code?: string;
}

export interface Session {
  id: number;
  slug: string;
  program: Program | number;
  name: string;
  description?: string | null;
  director?: Person | number;
  dateConfirmed?: boolean;
  primaryLocation?: string;
  primaryOvernightLocation?: string;
  ageRange?: string;
  maxParticipants?: number;
  registeredParticipants?: number;
  waitlist?: YesNo;
  approvalStatus?: string;
  runStatus: RunStatus;
  canceled?: boolean;
  canceledOn?: Date;
  typeCode?: string;
  typeInfo?: string;
  typeDisplay?: string;
  registeredStudentOrgType?: string;
  approvalSource?: string;
  approvalType?: string;
  approvalNotes?: string;
  custodialCare?: string;
  custodialCareDisplay?: string;
  youthSupervisionPlan?: Document | number;
  healthAndSafetyPlan?: Document | number;
  emergencyPlan?: Document | number;
  escalationMatrix?: Document | number;
  orientationAgenda?: Document | number;
  thirdPartyAgreement?: Document | number;
  staffRoster?: Document | number;
  startDate?: Date;
  endDate?: Date;
  excludeFromIntegrations?: boolean;
  participantCount?: number;
  staffCount?: number;
  staffBackgroundChecks?: boolean;
  staffRequiredTraining?: boolean;
  staffOrientation?: boolean;
  staffYoungestAge?: number;
  isCompliant?: boolean;
  createdAt?: Date;
  updatedAt?: Date;
  formsDue?: Date;
  formsClose?: Date;
  registrationOpen?: Date;
  registrationClose?: Date;
  fee?: number | string;
  deposit?: number | string;
  campusAdminFee: number | string;
  hasYouthSupervisionPlan?: boolean;
  hasHealthAndSafetyPlan?: boolean;
  hasEmergencyPlan?: boolean;
  hasEscalationMatrix?: boolean;
  hasOrientationAgenda?: boolean;
  hasThirdPartyAgreement?: boolean;
  hasStaffRoster?: boolean;
  hasVolunteers?: string;
  volunteerSupervisor?: string;
  volunteerSupervisorEmail?: string;
  grantFunded?: string;
  upwardBoundTrio?: string;
  dpiScholarship?: string;
  athleticActivity?: string;
  musicActivity?: string;
  waterActivity?: string;
  inStateTravel?: string;
  outStateTravel?: string;
  forCredit?: string;
  glCode?: string;
  league?: string;
  notes?: string;
}

export interface FormMenuItem {
  val?: string;
  text?: string;
}

export type YesNo = "y" | "n" | "na";

export type RunStatus =
  | "Canceled"
  | "Pending"
  | "Completed"
  | "Running"
  | "Unknown";

export const typeItems: FormMenuItem[] = [
  { val: "com", text: "Commuter" },
  { val: "res", text: "Residential" },
  { val: "rso", text: "Registered Student Organization" },
  { val: "ft", text: "Field Trip" },
  { val: "ind", text: "Individual" },
  { val: "oth", text: "Other" },
];

export const custodialCareItems: FormMenuItem[] = [
  { val: "uw", text: "UW Institution" },
  { val: "ap", text: "Affiliated Program" },
  { val: "tp", text: "Third Party" },
  { val: "ft", text: "Field Trip Organizer" },
  { val: "hy", text: "Hybrid (Defined in Contract)" },
  { val: "pg", text: "Parent/Guardian" },
];

export const yesNoItems: FormMenuItem[] = [
  { val: "na", text: "N/A" },
  { val: "y", text: "Yes" },
  { val: "n", text: "No" },
];

export const ageRangeItems: FormMenuItem[] = [
  { val: "e", text: "Elementary" },
  { val: "m", text: "Middle School" },
  { val: "h", text: "High School" },
  { val: "k", text: "K-12" },
];

export const orgTypeItems: FormMenuItem[] = [
  { val: "co", text: "University Co-Sponsored" },
  { val: "tp", text: "Third Party" },
];
